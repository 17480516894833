import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import { RoleContext } from "../role-provider";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { ConfirmNavigateAway } from "../components/ConfirmNavigateAway";
import { Loader } from "../components/loader/Loader";
import { saveFoodPromotion } from "../data/dashboard";
import { components } from "../data/dashboard.types";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../axios-provider";
import { withLayout } from "../hoc/with-layout";
import sanitizeHtml from "sanitize-html";
import { SANITIZE_OPTS_NO_TAGS } from "../utils";


type FoodPromotionRequest = components["schemas"]["FoodPromotionRequest"];

//removed countryCOde List as it is not used Platform 15170 amanjare

export const AddFoodPromo = withLayout(() => {
  //adding selectedCountry From RoleContext(User Selected) Platform 15170 amanjare
  const {  selectedCountry,selectedRole } = useContext(RoleContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const addFoodPromoRequest = saveFoodPromotion(apiClient);
  const { mutate, isLoading, isSuccess } = useMutation(
    (data: FoodPromotionRequest) => {
      //@ts-ignore
      
      return addFoodPromoRequest( {
        //to solve Add Food Promotion Date Issue Platform 15170 amanjare start
        ...data,
        startDate: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        endDate: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        //to solve Add Food Promotion Date Issue Platform 15170 amanjare end
      }, {
        countryCode: data.countryCode,
        roleId: selectedRole!,
      });
    },
    {
      onMutate: () => addFoodPromoRequest,
      onSuccess: () => navigate("/dashboard"),
    }
  );
  
 const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().add(1, "day"));
 const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().add(2, "day"));

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FoodPromotionRequest>({
    defaultValues: {
      countryCode: selectedCountry?selectedCountry:"",
      projectName: "",
      startDate: startDate?.format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: endDate?.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    },
  });
  
  const onSubmit = (data: FoodPromotionRequest) => {
   mutate(data);
  };


  return (
    <StyledLoaderMask>
      {isLoading && <Loader />}

      <ConfirmNavigateAway isDirty={isDirty && !isSuccess} />
      {isSuccess && <Navigate to="/dashboard" />}

      <StyledFoodPromoFormOuter>
        <StyledFoodPromoFormInner>
          <div>
            <StyledFormLabel variant="h4">New Food Promotion</StyledFormLabel>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  //to disable selectedCountry Dropdown Platform 15170 amanjare
                  disabled
                  label="Select Country"
                  {...register("countryCode")}
                  error={!!errors.countryCode}
                  required
                  select
                  aria-label="select country"
                  SelectProps={{ native: true }}
                  //to set user's selected country Platform 15170 amanjare
                  value={selectedCountry}
                >
                  <option value={selectedCountry!}>{selectedCountry}</option>
                </TextField>
                <TextField
                  label="Project Name"
                  {...register("projectName", {
                    required: true,
                    minLength: 2,
                    maxLength: 20,
                  })}
                  onBlur={(event) =>
                    setValue(
                      "projectName",
                      sanitizeHtml(
                        event.target.value,
                        SANITIZE_OPTS_NO_TAGS
                      )
                        .toString()
                        .trim()
                    )
                  }
                  aria-label="project name input"
                  data-testid="project-name-field"
                  error={!!errors.projectName}
                  helperText={
                    errors.projectName &&
                    "Project Name must be between 2 and 20 characters"
                  }
                />

                <DatePicker
                  sx={{
                    "> .Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  label="Start Date"
                  {...register("startDate", { required: "Start date is required" })}
                  value={startDate}
                  minDate={dayjs().add(1, "day")} 
                  aria-label="date picker"
                  onChange={(newValue) => setStartDate(newValue)}
                  autoFocus={true}
                />
                <DatePicker
                  sx={{
                    "> .Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  label="End Date"
                  {...register("endDate", { required: "End date is required" })}
                  value={endDate}
                  minDate={startDate?.add(1,"day")}
                  aria-label="date picker"
                  onChange={(newValue) => setEndDate(newValue)}
                  autoFocus={true}
                />
              </Stack>
              <StyledSubmitButton
                fullWidth
                size="large"
                type="submit"
                aria-label="submit"
                variant="contained"
              >
              
                Submit
              </StyledSubmitButton>
            </form>
          </div>
        </StyledFoodPromoFormInner>
      </StyledFoodPromoFormOuter>
    </StyledLoaderMask>
  );
}, "Add Food Promotion");

const StyledFoodPromoFormOuter = styled(Box)({
  flex: "1 1 auto",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});
const StyledFoodPromoFormInner = styled(Box)({
  maxWidth: 550,
  px: 3,
  py: "100px",
  width: "100%",
});
const StyledFormLabel = styled(Typography)({
  marginBottom: 24,
});
const StyledLoaderMask = styled("div")({
  position: "relative",
  height: "550px",
});
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
  marginTop: theme.spacing(3),
}));
