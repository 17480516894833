import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { RoleContext } from "../role-provider";
import { useForm } from "react-hook-form";
import sanitizeHtml  from "sanitize-html";
import { SANITIZE_OPTS_NO_TAGS } from "../utils";

type UOMModalProps = {
  onSaveUOM: (data: UOMType) => void;
  onCancel?: () => void;
};

type UOMType = {
  uom: string;
  uomId?: number;
  uomDesc: string;
  measurementType: string;
  languageCode?: string;
}[];

export const CreateUOMmodal = ({ onSaveUOM, onCancel }: UOMModalProps) => {
  const { selectableLanguages } = useContext(RoleContext);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }, 
  } = useForm<UOMType>();

  const handleSave = (data: UOMType) => {
    onSaveUOM(data);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledRelativeContainer>
          <DialogTitle id="delete-dialog-title">
            Add Unit Of Measurement
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="category-archive-description">
              Language Name
            </DialogContentText>
            {selectableLanguages.map((lang, index) => {
              const uomError = errors?.[index]?.uom;
              const uomDescError = errors?.[index]?.uomDesc;
              const measurementTypeError = errors?.[index]?.measurementType;

              return (
                <Grid
                  key={lang.languageCode}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid sx={{ mr: 6 }}>
                    <Typography variant="h6">{lang.languageName}</Typography>
                  </Grid>
                  {/* UOM Field */}
                  <StyledTextField
                    fullWidth
                    label="UOM"
                    error={!!uomError} // Display error state if uomError exists
                    helperText={uomError ? "UOM is required" : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register(`${index}.uom`, { required: true })}
                    onBlur={(event) =>
                      setValue(
                        `${index}.uom`,
                        sanitizeHtml(
                          event.target.value,
                          SANITIZE_OPTS_NO_TAGS
                        )
                          .toString()
                          .trim()
                      )
                    }
                  />
                  {/* UOM Description Field */}
                  <StyledTextField
                    fullWidth
                    label="UOM Description"
                    multiline
                    error={!!uomDescError} // Display error state if uomDescError exists
                    helperText={uomDescError ? "UOM Description is required" : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register(`${index}.uomDesc`, { required: true })}
                    onBlur={(event) =>
                      setValue(
                        `${index}.uomDesc`,
                        sanitizeHtml(
                          event.target.value,
                          SANITIZE_OPTS_NO_TAGS
                        )
                          .toString()
                          .trim()
                      )
                    }
                  />
                  {/* Measurement Type Field */}
                  <StyledTextField
                    label="Measurement Type"
                    select
                    fullWidth
                    error={!!measurementTypeError} // Display error state if measurementTypeError exists
                    helperText={
                      measurementTypeError ? "Measurement Type is required" : ""
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register(`${index}.measurementType`, {
                      required: true,
                      onChange: (event) => {
                        const value = event.target.value;
                        setValue(`${index}.measurementType`, value); // Set the value
                        if (value) {
                          // Clear the error if a valid value is selected
                          clearErrors(`${index}.measurementType`);
                        }
                      },
                    })}
                  >
                    <MenuItem value="metric">metric</MenuItem>
                    <MenuItem value="imperial">imperial</MenuItem>
                  </StyledTextField>
                </Grid>
              );
            })}
          </DialogContent>
          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Cancel
            </StyledActionButton>
            <Button type="submit" variant="contained">
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};

const StyledActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
}));

const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: "30px",
  marginLeft: "30px",
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
