import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Cancel, DeleteOutline, Save } from "@mui/icons-material";
import { withLayout } from "../hoc/with-layout";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { useForm } from "react-hook-form";
import { Loader } from "./loader/Loader";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { deleteUOM, getUOMByCountry, saveUOM } from "../data/miscellaneous";
import { ApiError, SANITIZE_OPTS_NO_TAGS, isUOMList } from "../utils";
import { LanguageSelector } from "./LanguageSelector";
import { CreateUOMmodal } from "./CreateUOMmodal";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { ResendFormModal } from "./ResendFormModal";
import sanitizeHtml from "sanitize-html";
import { AxiosError } from "axios";
type UOMType = {
  uomId: number;
  uom: string;
  uomDesc: string;
  measurementType: string;
};

type UOMResult = {
  [key: string]: {
    uom: string;
    uomDesc: string;
    measurementType: string;
  };
};
export const UnitOfMeasurement = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const {
    selectedCountry,
    defaultLanguage,
    selectableLanguages,
    selectedRole,
    isReaderRole,
  } = useContext(RoleContext);
  const { handleSubmit, register, reset, getValues, setValue } =
    useForm<UOMType>();

  const [isCreateUomModalOpen, setIsCreateUomModalOpen] = useState(false);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  const [editingUOMId, setEditingUOMId] = useState<number | null>(null);
  const [uomToDeleteId, setUomToDeleteId] = useState<number | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const handleOffEditingMode = () => {
    setEditingUOMId(null);
    setIsCreateUomModalOpen(false);
  };
  const handleOnEditingMode = (uom: UOMType) => {
    reset({
      uomId: uom.uomId,
      uom: uom.uom,
      uomDesc: uom.uomDesc,
      measurementType: uom.measurementType,
    });
    setEditingUOMId(uom.uomId);
  };
  const toggleCreateUOMModal = () => {
    setIsCreateUomModalOpen(!isCreateUomModalOpen);
  };

  const uomListQuery = useCustomQuery(["getUOMByCountry"], () =>
    getUOMByCountry(apiClient)({
      countryCode: selectedCountry!,
    })
  );
  const uomList = useMemo(() => {
    const maybeUOMList = uomListQuery.data?.data.uomlist;
    if (isUOMList(maybeUOMList)) {
      return maybeUOMList;
    }
    return {};
  }, [uomListQuery.data]);

  const getUOMValuesByKeyName = useCallback(
    (language: string): UOMType[] => {
      if (!uomList[language]) {
        return [];
      }
      return uomList[language];
    },
    [uomList]
  );

  const deleteMutation = useMutation(
    (uomId: number) =>
      deleteUOM(apiClient)({
        uomId: String(uomId),
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
        roleId: selectedRole!,
      }),
    {
      onSuccess: () => {
        setSuccessMessage("Unit of Measurement deleted successfully");
        setUomToDeleteId(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getUOMByCountry"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
        setUomToDeleteId(null);
      },
    }
  );

  const onConfirmDelete = useCallback(() => {
    if (uomToDeleteId) {
      deleteMutation.mutate(uomToDeleteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomToDeleteId]);

  const saveUOMMutation = saveUOM(apiClient);
  const { mutate, isLoading } = useMutation(
    (data: UOMResult) => {
      const metaData = {
        ...(editingUOMId && {
          uomId: Number(editingUOMId),
          languageCode: selectedLanguage!,
        }),

        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      };

      return saveUOMMutation(data, metaData);
    },
    {
      onMutate: () => saveUOMMutation,
      onSuccess: () => {
        handleOffEditingMode();
        queryClient.invalidateQueries(["getUOMByCountry"]);

        setSuccessMessage(`UOM saved successfully`);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onSubmit = (data: any) => {
    const transformedData = {
      [selectedLanguage!]: {
        uom: data.uom,
        uomDesc: data.uomDesc,
        measurementType: data.measurementType,
      },
    };
    mutate(transformedData);
  };
  const handleSaveUOM = (data: any) => {
    const transformedData = selectableLanguages.reduce<UOMResult>(
      (acc, lang, index) => {
        const languageCode = lang.languageCode || "";
        acc[languageCode] = data[index];
        return acc;
      },
      {}
    );
    mutate(transformedData);
  };
  const isWorking = useMemo(
    () => uomListQuery.isFetching || isLoading || deleteMutation.isLoading,
    [deleteMutation.isLoading, isLoading, uomListQuery.isFetching]
  );
  return (
    <StyledRelativeContainer container spacing={1}>
      {isWorking && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid container mobile={12} spacing={0}>
            <Grid mobile={6}>
              <Typography variant="h1" sx={{ margin: "32px 0 16px" }}>
                Unit of Measurement
              </Typography>
            </Grid>
            <StyledLanguageSelectorWrapper container mobile={6}>
              <LanguageSelector
                selectedLanguage={selectedLanguage!}
                onChange={(language) => setSelectedLanguage(language)}
              />
            </StyledLanguageSelectorWrapper>
            {!isReaderRole && (
              <Grid
                container
                mobile={12}
                justifyContent="flex-end"
                sx={{ my: 2 }}
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    toggleCreateUOMModal();
                  }}
                  sx={{ backgroundColor: "#DA291C" }}
                >
                  Add new Unit of Measurement
                </Button>
              </Grid>
            )}
            <Grid mobile={12}>
              <Stack spacing={1} marginBottom="20px">
                <TableContainer component={Paper}>
                  <Table aria-label="UOM List Table">
                    <StyledDashboardTableHead>
                      <TableRow>
                        <TableCell>{`UOM (${selectedLanguage})`}</TableCell>
                        <TableCell>UOM Description</TableCell>
                        <TableCell>Measurement Type</TableCell>
                        {!isReaderRole && (
                          <TableCell align="right">Action</TableCell>
                        )}
                      </TableRow>
                    </StyledDashboardTableHead>
                    <TableBody>
                      {getUOMValuesByKeyName(selectedLanguage!).map(
                        (uom, index) => {
                          const isEditing: boolean = editingUOMId === uom.uomId;
                          return isEditing ? (
                            <StyledTableRow key={index}>
                              <TableCell component="th" scope="row">
                                <TextField
                                  fullWidth
                                  size="small"
                                  defaultValue={uom.uom}
                                  {...register("uom", { required: true })}
                                  onBlur={(event) =>
                                    setValue(
                                      "uom",
                                      sanitizeHtml(
                                        event.target.value,
                                        SANITIZE_OPTS_NO_TAGS
                                      )
                                        .toString()
                                        .trim()
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  size="small"
                                  defaultValue={uom.uomDesc}
                                  {...register("uomDesc", { required: true })}
                                  onBlur={(event) =>
                                    setValue(
                                      "uomDesc",
                                      sanitizeHtml(
                                        event.target.value,
                                        SANITIZE_OPTS_NO_TAGS
                                      )
                                        .toString()
                                        .trim()
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  size="small"
                                  fullWidth
                                  defaultValue={uom.measurementType}
                                  disabled={true}
                                  {...register(`measurementType`)}
                                >
                                  <MenuItem value="metric">metric</MenuItem>
                                  <MenuItem value="imperial">imperial</MenuItem>
                                </TextField>
                              </TableCell>
                              <TableCell align="right">
                                <>
                                  <IconButton onClick={handleOffEditingMode}>
                                    <Cancel />
                                  </IconButton>
                                  <IconButton autoFocus type="submit">
                                    <Save />
                                  </IconButton>
                                </>
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            <StyledTableRow key={index}>
                              <TableCell component="th" scope="row">
                                {uom.uom}
                              </TableCell>
                              <TableCell>{uom.uomDesc}</TableCell>
                              <TableCell>{uom.measurementType}</TableCell>
                              {!isReaderRole && (
                                <TableCell align="right">
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        handleOnEditingMode(uom);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        setUomToDeleteId(uom.uomId!)
                                      }
                                    >
                                      <DeleteOutline
                                        sx={{ color: "#DA291C" }}
                                      />
                                    </IconButton>
                                  </>
                                </TableCell>
                              )}
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {isCreateUomModalOpen && (
        <CreateUOMmodal
          onSaveUOM={handleSaveUOM}
          onCancel={toggleCreateUOMModal}
        />
      )}
      {typeof uomToDeleteId === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={deleteMutation.isLoading}
          message="It is an irreversible process. Are you sure you want to delete unit
          of measurement?"
          onConfirm={onConfirmDelete}
          onCancel={() => setUomToDeleteId(null)}
        />
      )}
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          setIsResendModalOpen(false);
          const formData = getValues();
          onSubmit(formData);
        }}
        onCancel={() => {
          setUomToDeleteId(null);
          setIsRetryDeleteModalOpen(false);
        }}
        description="An error occurred while deleting the unit of measurement"
      />
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={() => {
          setIsRetryDeleteModalOpen(false);
          onConfirmDelete();
        }}
        onCancel={() => {
          setUomToDeleteId(null);
          setIsRetryDeleteModalOpen(false);
        }}
        description="An error occurred while deleting the unit of measurement"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Unit of Measurement");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledLanguageSelectorWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  justifyContent: "flex-end",
}));
