import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import {
  getAllRolesByCountry,
  getUserDetailsById,
  saveUser,
} from "../data/miscellaneous";
import {
  ApiError,
  SANITIZE_OPTS_NO_TAGS,
  ValidRole,
  ValidUserDetails,
  isRole,
  isUserById,
} from "../utils";
import { withLayout } from "../hoc/with-layout";
import { useForm } from "react-hook-form";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { Loader } from "./loader/Loader";
import { SuccessAlertSnackbar, ErrorAlertSnackbar } from "./AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";
import sanitizeHtml from "sanitize-html";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

export const UserForm = withLayout(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const { userId } = useParams();
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const onCloseSuccessAlert = () => navigate("/view-user");

  const { data: getUserDetailsByIdQuery, isFetching: isFetchingEditUserQuery } =
    useCustomQuery(
      ["getUserDetailsById"],
      () =>
        getUserDetailsById(apiClient)({
          countryCode: selectedCountry!,
          userId: userId!,
        }),
      typeof userId !== "undefined"
    );
  const userDetails = useMemo(() => {
    const userData = getUserDetailsByIdQuery?.data.userData;
    if (userData && isUserById(userData)) {
      return userData;
    }
    return null;
  }, [getUserDetailsByIdQuery?.data.userData]);

  const { data: getAllRolesByCountryQuery, isFetching: isFetchingRolesQuery } =
    useCustomQuery(["getAllRolesByCountry"], () =>
      getAllRolesByCountry(apiClient)({
        countryCode: selectedCountry!,
      })
    );
  const roleList = useMemo(() => {
    if (getAllRolesByCountryQuery?.data.role === undefined) {
      return [];
    }
    const validRoleList = getAllRolesByCountryQuery?.data.role.filter(
      (MaybeRole): MaybeRole is ValidRole => {
        return isRole(MaybeRole) === true;
      }
    );
    return validRoleList;
  }, [getAllRolesByCountryQuery?.data.role]);

  const isEditing = useMemo(() => typeof userId !== "undefined", [userId]);

  const initialSelectedRoles = useMemo(() => {
    return isEditing && userDetails?.roleIds
      ? userDetails?.roleIds.split(",").map((id) => Number(id))
      : [];
  }, [userDetails?.roleIds, isEditing]);

  const [selectedRoles, setSelectedRoles] =
    useState<number[]>(initialSelectedRoles);

  const saveUserRequest = saveUser(apiClient);
  const saveUserMutation = useMutation(
    (data: ValidUserDetails) => {
      const savingUser = {
        userId: data.eid,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        roles: data.roleIds,
        remarks: data.remarks,
      };

      const metaData = {
        ...(typeof userId !== "undefined" ? { userId: userId } : {}),
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      };
      return saveUserRequest(savingUser, metaData);
    },
    {
      onMutate: () => saveUserRequest,
      onSuccess: () => {
        setSuccessMessage(`User details successfully saved`);
        queryClient.invalidateQueries(["getUserDetailsById", { userId }]);
        queryClient.invalidateQueries(["getUsersData"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<ValidUserDetails>();

  const handleCheckboxChange = (roleId: number) => {
    setSelectedRoles((prevSelectedRoles) => {
      const newSelectedRoles = prevSelectedRoles.includes(roleId)
        ? prevSelectedRoles.filter((id) => id !== roleId)
        : [...prevSelectedRoles, roleId];
      setValue("roleIds", newSelectedRoles.sort((a, b) => a - b).join(","), {
        shouldDirty: true,
      });
      return newSelectedRoles;
    });
    if (selectedRoles.length === 0) {
      clearErrors("roleIds");
    }
  };
  useEffect(() => {
    // Triggered every time the userId changes, including when it's undefined
    const roleIdsArray =
      isEditing && userDetails?.roleIds
        ? userDetails.roleIds.split(",").map(Number)
        : [];

    if (isEditing && userDetails) {
      // Editing existing user: Initialize form with userDetails and selectedRoles
      reset({
        ...userDetails,
        roleIds: userDetails.roleIds,
      });
      setSelectedRoles(roleIdsArray);
    } else {
      // Adding a new user or userId changed to undefined: Reset form and selectedRoles
      reset({
        eid: "",
        firstName: "",
        lastName: "",
        email: "",
        roleIds: "",
        remarks: "",
      });
      setSelectedRoles([]);
    }
  }, [userId, userDetails, isEditing, reset]);
  const isFetching = useMemo(
    () =>
      isFetchingEditUserQuery ||
      isFetchingRolesQuery ||
      saveUserMutation.isLoading,
    [isFetchingEditUserQuery, isFetchingRolesQuery, saveUserMutation.isLoading]
  );

  const onSubmit = (data: ValidUserDetails) => {
    if (selectedRoles.length === 0) {
      setError("roleIds", {
        type: "manual",
        message: "Selecting at least one role is mandatory",
      });
    } else {
      clearErrors("roleIds");
      saveUserMutation.mutate(data);
    }
  };

  return (
    <StyledRelativeContainer>
      {isFetching && <Loader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="cy-add-edit-user-form"
      >
        <Grid container spacing={0}>
          <Grid mobile={12} container sx={{ mb: 6 }}>
            <Typography variant="h1">Add/Edit User</Typography>
          </Grid>
          <Grid mobile={12} sx={{ mb: 3 }}>
            <StyledTextField
              fullWidth
              disabled
              label="Country*"
              InputLabelProps={{ shrink: true }}
              select
              SelectProps={{ native: true }}
              value={selectedCountry}
            >
              <option value={selectedCountry!}>{selectedCountry}</option>
            </StyledTextField>
          </Grid>
          <Grid mobile={12} sx={{ mb: 3 }}>
            <StyledTextField
              InputLabelProps={{ shrink: true }}
              label="EID*"
              variant="outlined"
              fullWidth
              disabled={isEditing}
              error={!!errors.eid}
              helperText={errors.eid?.message}
              {...register("eid", {
                required: "EID is required",
                pattern: {
                  value: /^[a-zA-Z0-9]{8}$/,
                  message: "EID must be 8 digits alphanumeric",
                },
              })}
              onBlur={(event) =>
                setValue(
                  "eid",
                  sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                    .toString()
                    .trim()
                )
              }
              data-testid="cy-user-eid"
            />
          </Grid>
          <Grid mobile={6} sx={{ mb: 3, pr: 4 }}>
            <StyledTextField
              InputLabelProps={{ shrink: true }}
              label="First Name*"
              variant="outlined"
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              {...register("firstName", {
                required: "First Name is required",
                pattern: {
                  value: /^[\w\s]+$/,
                  message: "First Name must only contain letters",
                },
              })}
              onBlur={(event) =>
                setValue(
                  "firstName",
                  sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                    .toString()
                    .trim()
                )
              }
              data-testid="cy-user-first-name"
            />
          </Grid>
          <Grid mobile={6} sx={{ mb: 3 }}>
            <StyledTextField
              InputLabelProps={{ shrink: true }}
              label="Last Name*"
              variant="outlined"
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              {...register("lastName", {
                required: "Last Name is required",
                pattern: {
                  value: /^[\w\s]+$/,
                  message: "Last Name must only contain letters",
                },
              })}
              onBlur={(event) =>
                setValue(
                  "lastName",
                  sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                    .toString()
                    .trim()
                )
              }
              data-testid="cy-user-last-name"
            />
          </Grid>
          <Grid mobile={12} sx={{ mb: 3 }}>
            <StyledTextField
              InputLabelProps={{ shrink: true }}
              label="Email*"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Invalid email format",
                },
              })}
              onBlur={(event) =>
                setValue(
                  "email",
                  sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                    .toString()
                    .trim()
                )
              }
              data-testid="cy-user-email"
            />
          </Grid>
          <Grid
            mobile={12}
            sx={{
              mb: 3,
              p: 1,
              border: "2px rgba(0, 0, 0, 0.1) solid",
              borderRadius: "4px",
            }}
          >
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                component="legend"
                sx={{
                  marginBottom: "8px",
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "0.875rem",
                  "&.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.6)",
                  },
                }}
              >
                Roles*
              </FormLabel>
              <FormGroup row>
                {roleList.map((role) => (
                  <FormControlLabel
                    key={role.roleId}
                    control={
                      <Checkbox
                        value={role.roleId}
                        onChange={() => handleCheckboxChange(role.roleId)}
                        checked={selectedRoles.includes(role.roleId)}
                        data-testid="cy-user-role-checkbox"
                      />
                    }
                    label={role.roleName}
                  />
                ))}
              </FormGroup>
              {errors.roleIds && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {errors.roleIds.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid mobile={12} sx={{ mb: 3 }}>
            <StyledTextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Remarks"
              {...register("remarks")}
              onBlur={(event) =>
                setValue(
                  "remarks",
                  sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                    .toString()
                    .trim()
                )
              }
              data-testid="cy-user-remarks"
            />
          </Grid>
          <Grid
            mobile={12}
            sx={{
              p: 0,
              my: 4,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <StyledSecondaryButton
              variant="contained"
              size="large"
              aria-label="Reset"
              disabled={!isDirty}
              onClick={() => {
                if (userDetails) {
                  const roleIdsArray =
                    userDetails.roleIds?.split(",").map(Number) || [];
                  setSelectedRoles(roleIdsArray);
                  reset({
                    ...userDetails,
                    roleIds: userDetails.roleIds,
                  });
                } else {
                  reset();
                  setSelectedRoles([]);
                }
              }}
              data-testid="cy-user-reset-button"
            >
              Reset
            </StyledSecondaryButton>
            <StyledButton
              type="submit"
              variant="contained"
              size="large"
              aria-label="Save Product"
              data-testid="cy-user-save-button"
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => {
          setSuccessMessage(null);
          onCloseSuccessAlert();
        }}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Add/Edit User");

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
