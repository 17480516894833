import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import sanitizeHtml  from "sanitize-html";
import { ApiError, SANITIZE_OPTS_NO_TAGS } from "../utils";
import { saveRole } from "../data/miscellaneous";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { AxiosError } from "axios";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { Loader } from "./loader/Loader";

type RoleModalProps = {
  onSaveSuccess: () => void;
  onCancel: () => void;
};
export type RoleForm = {
  roleName: string;
};
export const CreateRoleModal = ({
  onSaveSuccess,
  onCancel,
}: RoleModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RoleForm>();

  const saveRoleRequest = saveRole(apiClient);
  const { mutate: doSaveRole, isLoading } = useMutation(
    (data: RoleForm) => {
      const metaData = {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      };
      return saveRoleRequest(data, metaData);
    },
    {
      onMutate: () => saveRoleRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError(
            "There was an error creating the role. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(errorMessage.message);
        } else {
          setApiError(String(error));
        }
      },
    }
  );

  const onSave = (data: RoleForm) => {
    setApiError(null);
    doSaveRole(data);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSave)}>
        <StyledRelativeContainer>
          {isLoading && <Loader />}
          <DialogTitle id="new-role-dialog-title">New Role</DialogTitle>
          <DialogContent>
            {apiError && (
              <Grid marginLeft={3} marginTop={3}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledTextField
                fullWidth
                label="Role Name"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register(`roleName`, { required: true })}
                onBlur={(event) =>
                  setValue(
                    `roleName`,
                    sanitizeHtml(
                      event.target.value,
                      SANITIZE_OPTS_NO_TAGS
                    )
                      .toString()
                      .trim()
                  )
                }
                error={!!errors.roleName}
                helperText={errors.roleName && `Role name is required`}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Cancel
            </StyledActionButton>
            <Button type="submit" variant="contained">
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});

const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(3),
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
