import { styled } from "@mui/material/styles";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { withLayout } from "../hoc/with-layout";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { getRoundingRuleById, saveRoundingRule } from "../data/roundingrules";
import { useAxios } from "../axios-provider";
import { Controller, useForm } from "react-hook-form";
import { formatRoundingRuleForEdit } from "../util/rounding-rules";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { StyledSecondaryButton } from "../components/ItemMarketingForm";
import sanitizeHtml from "sanitize-html";
import { ApiError, SANITIZE_OPTS_NO_TAGS } from "../utils";
import { Loader } from "../components/loader/Loader";
import { AxiosError } from "axios";
import {
  localizedNumStringOrEmpty,
  localizedStringValueToFloat,
  standardizedNumStringOrEmpty,
} from "../util/number-localization";

type RoundingRuleForm = {
  roundingRuleName: string;
  roundingModeId: number;
  roundingConstant: string;
  description: string;
};

export const CreateEditRoundingRule = () => {
  const { roundingRuleId } = useParams();
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } =
    useContext(RoleContext);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { data, isFetching } = useCustomQuery(
    [
      "getRoundingRuleById",
      {
        roundingRuleId,
        countryCode: selectedCountry,
      },
    ],
    () =>
      getRoundingRuleById(apiClient)({
        roundingRuleId: Number(roundingRuleId)!,
        countryCode: selectedCountry!,
      }),
    typeof roundingRuleId !== "undefined"
  );

  const initialData: RoundingRuleForm = useMemo(() => {
    if (data?.data.dataList) {
      const validRoundingRule = formatRoundingRuleForEdit(
        data.data.dataList,
        selectedCountry!
      );
      if (validRoundingRule) {
        return validRoundingRule;
      }
    }
    return {
      roundingModeId: 0,
      roundingRuleName: "",
      description: "",
      roundingConstant: "",
    };
  }, [data, selectedCountry]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm<RoundingRuleForm>();

  useEffect(() => {
    reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const saveRoundingRuleRequest = saveRoundingRule(apiClient);
  const { mutate, isLoading } = useMutation(
    (data: RoundingRuleForm) => {
      const metaData = {
        ...(typeof roundingRuleId !== "undefined"
          ? { roundingRuleId: Number(roundingRuleId) }
          : {}),
        countryCode: selectedCountry!,
        roleId: Number(selectedRole)!,
      };
      // change key roundingModeId -> roundingMode
      const { roundingModeId, roundingConstant, ...body } = data;
      return saveRoundingRuleRequest(
        {
          roundingMode: roundingModeId,
          roundingConstant: standardizedNumStringOrEmpty(
            roundingConstant,
            selectedCountry!
          ),
          ...body,
        },
        metaData
      );
    },
    {
      onMutate: () => saveRoundingRuleRequest,
      onSuccess: () => {
        setSuccessMessage("Rounding rule was saved successfully");
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onSubmit = useCallback((formData: RoundingRuleForm) => {
    mutate(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormReset = useCallback(() => reset(initialData), [initialData]);

  const isWorking = useMemo(
    () => isFetching || isLoading,
    [isFetching, isLoading]
  );

  const CreateEditRoundingRuleWithLayout = withLayout(() => {
    return (
      <StyledRelativeContainer>
        <Box sx={{ flexGrow: 1, px: 0 }}>
          <Grid container>
            <Grid container mobile={9} sx={{ alignItems: "center", mb: 4 }}>
              <Grid>
                <Typography variant="h2">Rounding Rule</Typography>
              </Grid>
            </Grid>
            <Grid mobile={12}>
              {isWorking && <Loader />}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0}>
                  <Grid mobile={12} sx={{ mb: 3 }}>
                    <Controller
                      name={`roundingModeId`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <StyledTextField
                          fullWidth
                          label="Rounding Mode*"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                          error={!!errors.roundingModeId}
                          helperText={
                            errors.roundingModeId &&
                            "Please select a rounding mode"
                          }
                          select
                          SelectProps={{ native: true }}
                          data-testid="cy-open-rounding-rule-type-select"
                        >
                          <option key={1} value={1}>
                            ROUND TO CONSTANT
                          </option>
                          <option key={2} value={2}>
                            ROUND TO VALUE
                          </option>
                          <option key={3} value={3}>
                            NO ROUNDING
                          </option>
                        </StyledTextField>
                      )}
                    />
                  </Grid>
                  <Grid mobile={12} sx={{ mb: 3 }}>
                    <Controller
                      name={`roundingRuleName`}
                      control={control}
                      rules={{
                        required: "Name is required",
                      }}
                      render={({ field }) => (
                        <StyledTextField
                          InputLabelProps={{ shrink: true }}
                          label="Rounding Rule Name*"
                          variant="outlined"
                          fullWidth
                          {...field}
                          onBlur={(event) =>
                            setValue(
                              "roundingRuleName",
                              sanitizeHtml(
                                event.target.value,
                                SANITIZE_OPTS_NO_TAGS
                              )
                                .toString()
                                .trim()
                            )
                          }
                          data-testid="cy-rounding-rule-name"
                          error={!!errors.roundingRuleName}
                          helperText={errors.roundingRuleName?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid mobile={12} sx={{ mb: 3 }}>
                    <Controller
                      name={`description`}
                      control={control}
                      render={({ field }) => (
                        <StyledTextField
                          InputLabelProps={{ shrink: true }}
                          label="Description"
                          variant="outlined"
                          fullWidth
                          multiline
                          {...field}
                          onBlur={(event) =>
                            setValue(
                              "description",
                              sanitizeHtml(
                                event.target.value,
                                SANITIZE_OPTS_NO_TAGS
                              )
                                .toString()
                                .trim()
                            )
                          }
                          data-testid="cy-rounding-rule-description"
                          error={!!errors.description}
                          helperText={errors.description?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid mobile={12} sx={{ mb: 3 }}>
                    <Controller
                      name={`roundingConstant`}
                      control={control}
                      rules={{
                        required: "Rounding constant is required",
                        validate: (value) => {
                          const floatVal = localizedStringValueToFloat(
                            String(value),
                            selectedCountry!
                          );
                          return (
                            !isNaN(floatVal) ||
                            "Rounding constant must be numeric"
                          );
                        },
                      }}
                      render={({ field }) => (
                        <StyledTextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Rounding Constant*"
                          {...field}
                          onFocus={() => {
                            setValue(
                              `roundingConstant`,
                              standardizedNumStringOrEmpty(
                                getValues(`roundingConstant`),
                                selectedCountry!
                              )
                            );
                          }}
                          onBlur={(event) => {
                            const formattedValue = localizedNumStringOrEmpty(
                              event.target.value,
                              selectedCountry!
                            );
                            setValue(
                              `roundingConstant`,
                              formattedValue !== ""
                                ? formattedValue
                                : event.target.value
                            );
                            field.onBlur();
                          }}
                          data-testid="cy-rounding-rule-constant"
                          error={!!errors.roundingConstant}
                          helperText={
                            !!errors.roundingConstant &&
                            errors.roundingConstant.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    mobile={12}
                    sx={{
                      p: 0,
                      my: 4,
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <StyledSecondaryButton
                      variant="contained"
                      size="large"
                      aria-label="Reset"
                      disabled={!isDirty}
                      onClick={handleFormReset}
                      data-testid="cy-rounding-rule-reset-button"
                    >
                      Reset
                    </StyledSecondaryButton>
                    <StyledButton
                      type="submit"
                      variant="contained"
                      size="large"
                      aria-label="Save Product"
                      data-testid="cy-rounding-rule-save-button"
                    >
                      Save
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
              <ResendFormModal
                open={isResendModalOpen}
                onResend={() => {
                  const formValues = getValues();
                  onSubmit(formValues);
                  setIsResendModalOpen(false);
                }}
                onCancel={() => setIsResendModalOpen(false)}
              />
              <SuccessAlertSnackbar
                message={successMessage}
                onClose={() => {
                  setSuccessMessage(null);
                  navigate("/rounding-rules");
                }}
              />
              <ErrorAlertSnackbar
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledRelativeContainer>
    );
  }, "Rounding Rule");

  return <CreateEditRoundingRuleWithLayout />;
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
