import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RoleContext } from "../role-provider";
import { useItem } from "../pages/item";
import { useAxios } from "../axios-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getItemReviewAndSubmit, saveItemReviewSubmit } from "../data/items";
import {
  ApiError,
  FormattedItemReviewSubmitData,
  SANITIZE_OPTS_NO_TAGS,
  formatItemReviewSubmitData,
} from "../utils";
import { Loader } from "./loader/Loader";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import sanitizeHtml from "sanitize-html";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

type ReviewAndSubmitFormValues = {
  workflowId: number;
  newComments: string;
};

export const ItemReviewAndSubmit = () => {
  const { itemId, isLive } = useItem();
  const { isReaderRole, selectedCountry, selectedRole, selectedRoleName } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getItemReviewSubmitQuery = useCustomQuery(
    ["getItemReviewAndSubmit", { itemId }],
    () =>
      getItemReviewAndSubmit(apiClient)({
        itemId: Number(itemId),
        countryCode: selectedCountry!,
      })
  );

  const [itemReviewSubmitData, setItemReviewSubmitData] =
    useState<FormattedItemReviewSubmitData | null>(null);

  useEffect(() => {
    if (getItemReviewSubmitQuery.data?.data) {
      const formattedItemReviewSubmitData = formatItemReviewSubmitData(
        getItemReviewSubmitQuery.data.data
      );
      if (formattedItemReviewSubmitData) {
        setItemReviewSubmitData(formattedItemReviewSubmitData);
      }
    }
  }, [getItemReviewSubmitQuery.data]);

  const workflowOptions = useMemo(() => {
    if (itemReviewSubmitData) {
      return itemReviewSubmitData.workflows.filter((workflow) => {
        return ["No Workflow", "All Team Workflow"].includes(workflow.name);
      });
    }
  }, [itemReviewSubmitData]);

  const appliedWorkflowId = useMemo(() => {
    if (itemReviewSubmitData) {
      return itemReviewSubmitData.appliedWorkFlowId;
    }
  }, [itemReviewSubmitData]);

  const previousComments = useMemo(() => {
    if (itemReviewSubmitData) {
      return itemReviewSubmitData.comments;
    }
    return [];
  }, [itemReviewSubmitData]);

  const checkAndAckList = useMemo(() => {
    if (itemReviewSubmitData) {
      return itemReviewSubmitData.checkListAndAckList;
    }
  }, [itemReviewSubmitData]);

  const appliedWorkflowSteps = useMemo(() => {
    if (itemReviewSubmitData) {
      return itemReviewSubmitData.workFlowStepList;
    }
  }, [itemReviewSubmitData]);

  const allTeamSteps = useMemo(() => {
    if (!appliedWorkflowSteps || appliedWorkflowSteps.length === 0) {
      return [];
    }
    // the steps we want to render in the step counter should consist of:
    //  - Submitted (should always be FIRST)
    //  - A step for each role in the workflow step list from API
    // e.g. allTeamSteps for US looks like: ["Submit", "Marketing", "Nutritionist"]
    const stepNames = appliedWorkflowSteps.map((step) => step.stepName);
    stepNames.unshift("Submit");
    return stepNames;
  }, [appliedWorkflowSteps]);

  const appliedWorkflowName = useMemo(() => {
    if (appliedWorkflowId && workflowOptions) {
      const appliedWorkflow = workflowOptions.find((workflow) => {
        return workflow.id === appliedWorkflowId;
      });
      if (appliedWorkflow) {
        return appliedWorkflow.name;
      }
    }
    return undefined;
  }, [appliedWorkflowId, workflowOptions]);

  const isAllTeamWorkflowApplied = useMemo(() => {
    return appliedWorkflowName === "All Team Workflow";
  }, [appliedWorkflowName]);

  const isNoWorkflowApplied = useMemo(() => {
    return appliedWorkflowName === "No Workflow";
  }, [appliedWorkflowName]);

  const currentAllTeamsWorkflowStepName = useMemo(() => {
    if (itemReviewSubmitData && isAllTeamWorkflowApplied) {
      return itemReviewSubmitData.currentStep;
    }
  }, [itemReviewSubmitData, isAllTeamWorkflowApplied]);

  const currentAllTeamsWorkflowStepIndex = useMemo(() => {
    if (typeof currentAllTeamsWorkflowStepName !== "string") {
      return -1;
    }
    if (currentAllTeamsWorkflowStepName === "") {
      // empty string means last approval step is complete
      return allTeamSteps.length;
    }
    return allTeamSteps.indexOf(currentAllTeamsWorkflowStepName);
  }, [allTeamSteps, currentAllTeamsWorkflowStepName]);

  const isWorkflowSelectorEnabled = useMemo(() => {
    // only enable workflow selector if item does not already have a workflow applied
    return !isReaderRole && appliedWorkflowId === 0;
  }, [appliedWorkflowId, isReaderRole]);

  const {
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm<ReviewAndSubmitFormValues>({
    defaultValues: {
      workflowId: 0,
      newComments: "",
    },
  });
  useEffect(() => {
    if (itemReviewSubmitData) {
      reset(
        {
          workflowId: itemReviewSubmitData.appliedWorkFlowId ?? 0,
          newComments: "",
        },
        { keepDirty: false }
      );
    }
  }, [itemReviewSubmitData, reset]);
  const watchSelectedWorkflow = watch("workflowId");
  const isWorkflowSelected = useMemo(() => {
    // non-zero value of workflow selector -> workflow selected
    return watchSelectedWorkflow !== 0;
  }, [watchSelectedWorkflow]);

  const isCommentEnabled = useMemo(() => {
    if (isReaderRole || isNoWorkflowApplied) {
      // disable comment IF workflow = "No Workflow" is applied
      return false;
    }
    if (
      isAllTeamWorkflowApplied &&
      typeof currentAllTeamsWorkflowStepName === "string" &&
      selectedRoleName
    ) {
      // enable comment IF
      //   1) a workflow is applied, AND
      //   2) user's role lines up with the current step in workflow
      return (
        currentAllTeamsWorkflowStepName === selectedRoleName ||
        // hard-coded exception to handle the Country Admin/Administrator disparity
        (currentAllTeamsWorkflowStepName === "Country Admin" &&
          selectedRoleName === "Country Administrator")
      );
    } else if (isWorkflowSelected) {
      // enable comment if workflow was just selected
      return true;
    }
    // disable comment by default
    return false;
  }, [
    isAllTeamWorkflowApplied,
    isNoWorkflowApplied,
    isWorkflowSelected,
    isReaderRole,
    currentAllTeamsWorkflowStepName,
    selectedRoleName,
  ]);

  const renderComment = useCallback(
    (step: string, index: number, activeStep: number) => {
      if (previousComments) {
        const comment = previousComments[index];
        return (
          <Step key={index}>
            <StepLabel
              optional={
                activeStep > index ? (
                  <Typography variant="caption">
                    {comment ? comment.date : "No date available"}
                  </Typography>
                ) : null
              }
            >
              {step}
            </StepLabel>
            <StyledStepContent>
              {activeStep > index && (
                <Typography variant="body2">
                  <i>{comment ? comment.comments : "No comment available"}</i>
                </Typography>
              )}
            </StyledStepContent>
          </Step>
        );
      }
      return <></>;
    },
    [previousComments]
  );

  const renderAllTeamWorkflowStepper = useCallback(() => {
    if (!isAllTeamWorkflowApplied) {
      return <></>;
    }
    const isReadyForPushLive =
      currentAllTeamsWorkflowStepIndex === allTeamSteps.length;
    return (
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper
              activeStep={currentAllTeamsWorkflowStepIndex}
              orientation="vertical"
            >
              {allTeamSteps.map((step, index) =>
                renderComment(step, index, currentAllTeamsWorkflowStepIndex)
              )}
              <Step key={allTeamSteps.length + 1}>
                <StepLabel>Push Live</StepLabel>
                <StyledStepContent>
                  {isReadyForPushLive && (
                    <Typography variant="body2">
                      <i>Ready for Push Live</i>
                    </Typography>
                  )}
                </StyledStepContent>
              </Step>
            </Stepper>
          </Box>
        </CardContent>
      </Card>
    );
  }, [
    allTeamSteps,
    currentAllTeamsWorkflowStepIndex,
    isAllTeamWorkflowApplied,
    renderComment,
  ]);

  const renderNoWorkflowStepper = useCallback(() => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={1} orientation="vertical">
              {renderComment("No Workflow Completed", 0, 1)}
              {renderComment("Push Live", 1, 1)}
            </Stepper>
          </Box>
        </CardContent>
      </Card>
    );
  }, [renderComment]);

  const renderCheckAndAckList = useCallback(() => {
    return (
      <StyledReviewChecklist elevation={2}>
        <StyledReviewChecklistTitle>
          <Typography variant="h6">{`Review Checklist`}</Typography>
        </StyledReviewChecklistTitle>
        <List>
          {checkAndAckList?.map((checkListStep) => {
            return (
              <ListItem disablePadding>
                <ListItemButton>
                  <StyledListItemIcon>&bull;</StyledListItemIcon>
                  <Typography variant="caption">{checkListStep.ack}</Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </StyledReviewChecklist>
    );
  }, [checkAndAckList]);

  const saveItemReviewRequest = saveItemReviewSubmit(apiClient);
  const saveItemReviewMutation = useMutation(
    (data: ReviewAndSubmitFormValues) => {
      return saveItemReviewRequest(data, {
        countryCode: selectedCountry!,
        itemId: itemId,
        roleId: selectedRole!,
      });
    },
    {
      onMutate: () => saveItemReviewRequest,
      onSuccess: () => {
        // force refetch to update the stepper
        setSuccessMessage(`Item ${itemId} review was submitted`);
        queryClient.invalidateQueries(["getItemReviewAndSubmit", { itemId }]);
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    saveItemReviewMutation.mutate(data);
  };

  const isWorking = useMemo(
    () =>
      getItemReviewSubmitQuery.isFetching || saveItemReviewMutation.isLoading,
    [getItemReviewSubmitQuery.isFetching, saveItemReviewMutation.isLoading]
  );
  const handleReset = useCallback(() => {
    reset({
      workflowId: itemReviewSubmitData!.appliedWorkFlowId,
      newComments: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRelativeContainer>
      {isWorking && <Loader />}

      {!isWorking && getItemReviewSubmitQuery.isSuccess && (
        <Grid container spacing={4}>
          <StyledTitleBar container mobile={12}>
            <Typography variant="h2">Review Item</Typography>
            <StyledSecondaryButton
              variant="contained"
              disabled={!isLive}
              onClick={() => {
                navigate(`/items/${itemId}/compare-with-live`);
              }}
            >
              Compare with live
            </StyledSecondaryButton>
          </StyledTitleBar>
          <Grid item mobile={8}>
            {workflowOptions && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="item-review-submit"
              >
                <Grid container spacing={2}>
                  <Grid item mobile={12}>
                    <TextField
                      fullWidth
                      label="Workflow"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("workflowId", {
                        required: true,
                      })}
                      aria-labelledby="workflow-label"
                      disabled={!isWorkflowSelectorEnabled}
                      error={!!errors.workflowId}
                      helperText={
                        errors.workflowId && "Please select a workflow"
                      }
                      select
                      SelectProps={{ native: true }}
                      data-testid="workflow-type-selector"
                    >
                      <option key="" value=""></option>
                      {workflowOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item mobile={12}>
                    <TextField
                      label="Comments*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!isCommentEnabled}
                      multiline
                      fullWidth
                      rows={2}
                      {...register("newComments", { required: true })}
                      onBlur={(event) =>
                        setValue(
                          "newComments",
                          sanitizeHtml(
                            event.target.value,
                            SANITIZE_OPTS_NO_TAGS
                          )
                            .toString()
                            .trim()
                        )
                      }
                      error={!!errors.newComments}
                      helperText={
                        errors.newComments && "Comments cannot be blank"
                      }
                      data-testid="workflow-review-comments"
                    />
                  </Grid>
                  {isCommentEnabled && (
                    <Grid
                      item
                      mobile={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <StyledSecondaryButton
                        size="large"
                        type="reset"
                        disabled={!isDirty}
                        variant="contained"
                        onClick={handleReset}
                      >
                        Cancel
                      </StyledSecondaryButton>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </StyledButton>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Grid>
          <Grid item mobile={4}>
            {isAllTeamWorkflowApplied && renderAllTeamWorkflowStepper()}
            {isNoWorkflowApplied && renderNoWorkflowStepper()}
            {!isAllTeamWorkflowApplied && !isNoWorkflowApplied && (
              <StyledEmptyBox>
                <Typography variant="h6">{`There is no active workflow for this item`}</Typography>
              </StyledEmptyBox>
            )}
            {renderCheckAndAckList()}
          </Grid>
        </Grid>
      )}
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Container)({
  margin: 0,
  position: "relative",
  minHeight: "400px",
});
const StyledTitleBar = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginRight: 0,
  display: "flex",
  justifyContent: "space-between",
}));
const StyledStepContent = styled("div")({
  marginLeft: "12px",
  paddingLeft: "20px",
  paddingRight: "8px",
  borderLeft: "1px solid #bdbdbd",
  "& i": {
    fontStyle: "italic",
  },
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
const StyledReviewChecklist = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
const StyledReviewChecklistTitle = styled(ListItemText)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  textAlign: "center",
}));
const StyledListItemIcon = styled(ListItemIcon)({
  justifyContent: "center",
});
