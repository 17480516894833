import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { AuthContext } from "../auth-provider";
import { Logo } from "./Logo";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAccessToken } from "../data/auth";
import { useAxios } from "../axios-provider";
import packageInfo from "../../package.json";
import sanitizeHtml  from "sanitize-html";
import { SANITIZE_OPTS_NO_TAGS } from "../utils";

type EidLoginFormProps = {
  error?: string;
};
type FormValues = {
  eid: string;
};
interface CustomAxiosError {
  response?: {
    data?: {
      message?: string;
    };
  };
}
//setCsrfToken is added Platform 14222 amanjare
export const EidLoginForm = ({ error }: EidLoginFormProps) => {
  const {
    accessToken,    
    setCsrfToken,
    redirectDestination,
    setAccessToken,
    setRefreshToken,
    setEid,
    
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const version = packageInfo.version;

  const [errorMessage, setErrorMessage] = useState<string | null>(
    error || null
  );

  const getAccessTokenRequest = getAccessToken(apiClient);
  const loginWithEid = useMutation({
    mutationFn: (data) =>
      getAccessTokenRequest({
        ...data,
      }),
    onMutate: getAccessTokenRequest,
    onSuccess: (response) => {
      if (
        response.data.accessToken &&
        response.data.refreshToken &&
        response.data.eid
      
      ) {
        setAccessToken(response.data.accessToken);
        setRefreshToken(response.data.refreshToken);
        setEid(response.data.eid);
        //set csrfToken after login Platform 14222 amanjare
        setCsrfToken(response.data?.csrfToken?.token);
        queryClient.invalidateQueries(["getCountryAndRoleList"]);
      }
    },
    onError: (error: CustomAxiosError) => {
      const message =
        error?.response?.data?.message ?? "An unknown error occurred";
      setErrorMessage(`Login failed with error: ${message}`);
    },
  });

  const onSubmit = (data: FormValues) =>
    loginWithEid.mutate({
      eId: data.eid,
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (accessToken) {
      navigate(redirectDestination ?? "/dashboard");
    }
  }, [accessToken, redirectDestination, navigate]);
  return (
    <StyledRelativeContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} sx={{ p: 4 }}>
          <Logo />
          <Grid container sx={{ justifyContent: "center" }}>
            <Typography variant="body2">{`Version: ${version}`}</Typography>
          </Grid>
          {errorMessage && (
            <Alert variant="outlined" severity="error">
              {errorMessage}
            </Alert>
          )}
          <TextField
            {...register("eid", { required: "Employee ID is required" })}
            onBlur={(event) =>
              setValue(
                "eid",
                sanitizeHtml(event.target.value, SANITIZE_OPTS_NO_TAGS)
                  .toString()
                  .trim()
              )
            }
            error={!!errors.eid}
            data-testid="eid-input"
            helperText={errors.eid ? errors.eid.message : ""}
            label="Employee ID"
            variant="outlined"
            aria-label="Employee ID"
          />
          <StyledSubmitButton
            disableRipple
            fullWidth
            size="large"
            data-testid="login-btn"
            type="submit"
            variant="contained"
            aria-label="Login"
          >
            Login
          </StyledSubmitButton>
        </Stack>
      </form>
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
  marginTop: theme.spacing(3),
}));
